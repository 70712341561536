import React from "react";


const KeyFeatures = () => {
  return (
    <>
        <div className="keyfeatOuter">
            <div className="container">
                <div className="featHd">
                    <h2>KEY FEATURES</h2>
                </div>
                <div className="featList">
                  <ul>
                    <li>
                      <div className="featList1">
                        <h2>1</h2>
                        <p>
                          Access to a variety of games under the Gamesphere</p>
                          <p className="featBar">
                            <span>ecosystem</span>
                            <i></i>
                          </p>
                      </div>
                    </li>
                    <li>
                      <div className="featList2">
                        <h2>2</h2>
                        <p>
                          Gateway to Gamesphere's native marketplace to</p>
                          <p className="featBar">
                            <i></i>
                            <span>buy, sell and trade gaming assets</span>
                          </p>
                      </div>
                    </li>
                    <li>
                      <div className="featList1">
                        <h2>3</h2>
                        <p>
                          Low gas fee and fast</p>
                          <p className="featBar">
                            <span>transactions</span>
                            <i></i>
                          </p>
                      </div>
                    </li>
                  </ul>
              </div>
            </div>
        </div>
    </>
  )
}

export default KeyFeatures