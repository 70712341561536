import React, { useState, useEffect } from "react";
import Header from "../../common-components/header/header";
import TokanImg from "../../../assets/images/Token_icon.png";
import TransIcon1 from "../../../assets/images/trans-icon1.png";
import TransIcon2 from "../../../assets/images/trans-icon2.png";
import TransIcon3 from "../../../assets/images/trans-icon3.png";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUsdPrice } from "../../../redux/action/usdPriceAction";
import { userDetail } from "../../../redux/action/userAction";
import "./gsp-balance.scss";

import Web3 from 'web3';

const Gspbalance = () => {
  const dispatch = useDispatch();
  const usdPrice = useSelector((state) => state.gspUsdPrice);
  const { usdPriceData } = usdPrice;
  const userDetails = useSelector((state) => state.users);
  const { userData } = userDetails;
  const [copied, setCopied] = useState(false);
  const usdPrices = Number(usdPriceData?.data?.price) || 0;
  const gspBalance = Number(userData?.data?.gspBalance) || 0;
  const bnbBalance = Number(userData?.data?.ethBalance) || 0;
  const walletAddress = userData.data && userData.data.address;
  
  const [web3, setWeb3] = useState(null);
  const [account, setAccount] = useState(null);

  useEffect(() => {
    const initWeb3 = async () => {
      // Check if MetaMask is installed
      if (window.ethereum) {
        const web3Instance = new Web3(window.ethereum);
        setWeb3(web3Instance);

        try {
          // Request account access if needed
          await window.ethereum.enable();
          // Get the current account
          const accounts = await web3Instance.eth.getAccounts();
          setAccount(accounts[0]);
        } catch (error) {
          console.error('User denied account access');
        }
      } else {
        console.error('MetaMask not found');
      }
    }
    
    initWeb3();
  }, []);

  const handleTransfer = async () => {
    if (!web3 || !account) {
      console.error('Web3 or account not initialized');
      return;
    }

    // Replace 'recipientAddress' with your custodial wallet address
    const recipientAddress = userData.data.address;
    // Replace 'maticAmount' with the amount of Matic tokens to transfer
    const maticAmount = web3.utils.toWei('0.1', 'ether'); // Example: 1 Matic

    try {
      // Transfer Matic tokens
      await web3.eth.sendTransaction({
        from: account,
        to: recipientAddress,
        value: maticAmount
      });
      console.log('Transfer successful');
    } catch (error) {
      console.error('Error transferring tokens:', error);
    }
  };

  useEffect(() => {
    dispatch(userDetail());
    dispatch(getUsdPrice());
  }, [dispatch]);

  const walletAddressChange = (address) => {
    if (address) {
      let ar = address.split('')
      ar.splice(4, 26, ".....")
      return ar.join('')
    }
  }

  const copyToClipboard = () => {
    const tempInput = document.createElement('input');
    tempInput.value = walletAddress;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);

    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
  };

  return (
    <>
      <div className="mainBg">
        <div className="container">
          <Header />
          <div className="MidSecBx gspOutBx">
            <div className="balanceLeft">
              <div className="gspBalance">
                <div className="gspValue">
                  <i>
                    <img src={TokanImg} alt="" />
                  </i>
                  <span >Address: <Link onClick={copyToClipboard}>{walletAddressChange(userData.data && userData.data.address)}</Link></span>
                  <span >Recieve Matic: <Link onClick={handleTransfer}>Click</Link></span>
                  <span>$ GSP Balance: ${(usdPrices * gspBalance).toFixed(4)}</span>

                  <strong></strong>

                  <span>Matic Balance: {bnbBalance.toFixed(4)}</span>
                  <span>1 GSP = {usdPriceData.data && usdPriceData.data.price} USD</span>
                </div>
                <div className="finalValue">

                  <span>
                    <img src={TokanImg} alt="" />{userData.data && userData.data.gspBalance}
                  </span>

                </div>
              </div>
            </div>
            <div className="balanceRight">
              <div className="transList">
                <ul>
                  <li>
                    <img src={TransIcon1} alt="" />
                    <Link to="/transaction">Transactions</Link>
                  </li>
                  <li>
                    <img src={TransIcon3} alt="" />
                    <Link to="/buy-gsp">Buy Token</Link>
                  </li>
                  <li>
                    <img src={TransIcon2} alt="" />
                    <Link to="/game">Games</Link>
                  </li>

                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Gspbalance;
