import React from "react";
import { Button } from "reactstrap";
import Logo from "../../../assets/images/logo.png";
import Pdf from "../../../assets/images/GameSphere-whitepaper.pdf"
import { Link, useNavigate } from "react-router-dom";
import "./header.scss";
import Nav from "./nav";

const Header = () => {
  const navigate = useNavigate()
  const userToken = localStorage.getItem('userToken');
  const logOut = () => {
    localStorage.removeItem('userToken')
    window.location.assign("./");

  }
  return (
    <>
      <header>
        <div className="headLeft">
          <Link to="/">
            <img src={Logo} alt="" />
          </Link>
        </div>
        <div className="headRight">

          <Button><a href="/marketplace">Market Place</a></Button>
          <Button><a href={Pdf} target="_blank">Whitepaper</a></Button>
          <Button><a href="https://linktr.ee/gamesphere" target="_blank">Join The Community</a></Button>
          {!userToken ? <Button> <Link to="/login">Connect Wallet</Link></Button> : <Button> <Link onClick={logOut}>Disconnect Wallet</Link></Button>}
          {userToken ? <Nav /> : ""}

        </div>
      </header>
    </>
  );
};

export default Header;
