import React, { useState, useEffect } from "react";
import { Button, Input } from "reactstrap";
import Header from "../../common-components/header/header";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { GoAlert } from "react-icons/go";
import { Link, useNavigate } from "react-router-dom";
import ReactLoading from 'react-loading';
import { userRegister } from "../../../redux/action/userAction";
const initialState = {
    userName: '',
    email: '',
    mobile: '',
    password: '',
    isFlag: false,
    errors: {},
};

const Signup = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [iState, updateState] = useState(initialState);
    const { userName, email, mobile, password, isFlag, errors } = iState;

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        updateState({
            ...iState,
            [name]: value
        });

    };

    const handleValidation = () => {
        let userNameError = "";
        let emailIdError = "";
        let mobileError = "";
        let passwordError = "";


        let formIsValid = true;

        if (!userName) {
            userNameError = "Please Enter Name.";
            formIsValid = false;
        }
        if (!email) {
            emailIdError = "Please enter email ID.";
            formIsValid = false;
        } else
            if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email) || email.length > 255) {
                emailIdError = "Email is not valid or too long";
                formIsValid = false;
            }

        // if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)) {
        //     emailIdError = "Email format is not valid";
        //     formIsValid = false;
        // }
        if (!mobile) {
            mobileError = "Please Enter mobile number.";
            formIsValid = false;
        } else if (mobile.length < 10 || mobile.length > 15) {
            mobileError = "Please Enter a valid mobile number.";
            console.log("mobile.length --->", mobile.length);
            formIsValid = false;
        }

        if (!password) {
            passwordError = "Please Enter password.";
            formIsValid = false;
        }


        updateState({
            ...iState,
            errors: { userNameError, emailIdError, mobileError, passwordError },
        });
        return formIsValid;
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        let formIsValid = handleValidation();
        if (formIsValid) {
            let data = { userName, email, mobile, password };
            dispatch(userRegister(data))
                .then((res) => {

                    if (res.code == 200) {
                        toast.success(res.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        })
                        navigate("/")
                        updateState({
                            userName: "",
                            email: "",
                            mobile: "",
                            password: ""
                        });
                    } else {
                        console.log("error");
                        toast.error(res.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        })
                    }
                })
                .catch((err) => {
                    toast.error(" Error: Request failed with status code 400", {
                        position: toast.POSITION.TOP_RIGHT,
                    })
                    console.log(`error>>>> ${err}`);
                });


        } else {
            console.log("error");
        }

    };

    return (
        <>
            <div className="mainBg">
                <div className="container">
                    <Header />
                    <ToastContainer />
                    <div className="MidSecBx">
                        <div className="homeRight buygspBx signupPadd">
                            <div className="ConnetBx SIgnupOutr">
                                <div className="signupHd">
                                    <span>Create Wallet</span>
                                </div>
                                <div className="buyBx">
                                    <label>Name</label>
                                    <div className="gspFild">
                                        <div className="gspInn_Left">
                                            <Input
                                                type="text"
                                                name="userName"
                                                id="exampleEmail"
                                                placeholder="Name"
                                                value={userName}
                                                onChange={handleInputChange}
                                            />
                                        </div>


                                    </div>

                                    <span className="error">{errors && errors.userNameError ? <div><GoAlert /> {errors.userNameError}</div> : ""}</span>
                                </div>
                                <div className="buyBx">
                                    <label>Email</label>
                                    <div className="gspFild">
                                        <div className="gspInn_Left">
                                            <Input
                                                type="text"
                                                name="email"
                                                id="exampleEmail"
                                                placeholder="Email"
                                                value={email}
                                                onChange={handleInputChange}
                                            />
                                        </div>


                                    </div>
                                    <span className="error">{errors && errors.emailIdError ? <div><GoAlert /> {errors.emailIdError}</div> : ""}</span>
                                </div>
                                <div className="buyBx">
                                    <label>Mobile</label>
                                    <div className="gspFild">
                                        <div className="gspInn_Left">
                                            <Input
                                                type="number"
                                                name="mobile"
                                                id="exampleEmail"
                                                placeholder="Mobile"
                                                value={mobile}
                                                onChange={handleInputChange}
                                            />
                                        </div>


                                    </div>
                                    <span className="error">{errors && errors.mobileError ? <div><GoAlert /> {errors.mobileError}</div> : ""}</span>
                                </div>
                                <div className="buyBx">
                                    <label>Password</label>
                                    <div className="gspFild">
                                        <div className="gspInn_Left">
                                            <Input
                                                type="password"
                                                name="password"
                                                id="exampleEmail"
                                                placeholder="Password"
                                                value={password}
                                                onChange={handleInputChange}
                                            />
                                        </div>

                                    </div>
                                    <span className="error">{errors && errors.passwordError ? <div><GoAlert /> {errors.passwordError}</div> : ""}</span>
                                </div>
                                <div className="buyBx">
                                    <label className="signupButton">Already have a wallet? <Link to="/login">Connect Wallet</Link></label>
                                </div>

                                <div className="buygsp pt-3">
                                    <Button onClick={handleSubmit} disabled={isFlag}>Create Wallet</Button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Signup;
