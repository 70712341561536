import React, { useState, useEffect } from "react";
import Header from '../../common-components/header/header'
import Icon1 from '../../../assets/images/Token_icon.png'
import gameImg from '../../../assets/images/thumbnail_IMG_3377.jpg'
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getUsdPrice } from "../../../redux/action/usdPriceAction";
import { userDetail } from "../../../redux/action/userAction";
import "./game.scss"

const Games = () => {
    const dispatch = useDispatch()
    const usdPrice = useSelector((state) => state.gspUsdPrice);
    const { usdPriceData } = usdPrice;
    const userDetails = useSelector((state) => state.users);
    const { userData } = userDetails;
    const usdPrices = Number(usdPriceData?.data?.price) || 0;
    const gspBalance = Number(userData?.data?.gspBalance) || 0;

    useEffect(() => {
        dispatch(userDetail());
    }, [userDetail]);

    useEffect(() => {
        dispatch(getUsdPrice());
    }, [getUsdPrice]);


    return (
        <>
            <div className='gameBg'>
                <div className='container'>
                    <Header />
                    <div className='MidSecInner'>
                        <div className='totalValBx'>
                            <div className='totlValInn'>
                                <label>USD Total:</label>
                                <strong>${(usdPrices * gspBalance).toFixed(4)}</strong>
                            </div>
                            <div className='totlValInn'>
                                <label>$ GSP Balance:</label>
                                <span><img src={Icon1} alt="" />{userData.data && userData.data.gspBalance}</span>

                            </div>
                        </div>
                        <div className='gameListOuetr'>
                            <div className='ListBg_Out'>
                            <Link to="/game-transaction" style={{ textDecoration: 'none', color: 'inherit' }}>
                                <div className='gamelist'>
                                
                                    <div className='gamelist_Left'>
                                        <small>Super Cat</small>
                                        <strong>Game Sphere</strong>
                                        <span>Innovative Cat Game where Gamers can Collect Coins by Running at Different Levels</span>
                                    </div>

                                    <div className='gamelist_Right'> <img src={gameImg} alt="" /></div>
                                   
                                   
                                </div>
                                </Link>
                            </div>
                            <div className='ListBg_Out'>
                                <div className='gamelist'>
                                    <div className='gamelist_Left'>
                                        <small>Name of the game</small>
                                        <strong>Game  Studio Name</strong>
                                        <span>Some info about Game</span>
                                    </div>
                                    <div className='gamelist_Right'> <img src="" alt="" /></div>
                                    
                                </div>
                            </div>
                            <div className='ListBg_Out'>
                                <div className='gamelist'>
                                    <div className='gamelist_Left'>
                                        <small>Name of the game</small>
                                        <strong>Game  Studio Name</strong>
                                        <span>Some info about Game</span>
                                    </div>
                                    <div className='gamelist_Right'> <img src="" alt="" /></div>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default Games

