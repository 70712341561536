import React, { useState } from "react";
import { Button, Input } from "reactstrap";
import Header from "../../common-components/header/header";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { GoAlert } from "react-icons/go";
import { Link, useNavigate } from "react-router-dom";
import ReactLoading from 'react-loading';
import { userLogin } from "../../../redux/action/userAction";
const initialState = {
    email: '',
    password: '',
    errors: {},
};

const Login = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [iState, updateState] = useState(initialState);
    const { email, password, isFlag, errors } = iState;

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        updateState({
            ...iState,
            [name]: value
        });

    };

    const handleValidation = () => {
        let emailIdError = "";
        let passwordError = "";


        let formIsValid = true;


        if (!email) {
            emailIdError = "Please enter email ID.";
            formIsValid = false;
        } else if (!/^.+?@.+?\..+$/.test(email)) {
            emailIdError = "Email format is not valid";
            formIsValid = false;
        }
        if (!password) {
            passwordError = "Please Enter password.";
            formIsValid = false;
        }


        updateState({
            ...iState,
            errors: { emailIdError, passwordError },
        });
        return formIsValid;
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        let formIsValid = handleValidation();
        if (formIsValid) {
            let data = { email, password };
            dispatch(userLogin(data))
                .then((res) => {
                    console.log('res------>', res.message)
                    if (res.code == 200) {
                        localStorage.setItem('userToken', res.data.jwtToken);
                        toast.success(res.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        })
                        navigate("/gsp-balance")
                        updateState({
                            email: "",
                            password: ""
                        });
                    } else {
                        console.log("error");
                        toast.error(res && res.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        })
                    }
                })
                .catch((err) => {
                    toast.error(" Error: Request failed with status code 400", {
                        position: toast.POSITION.TOP_RIGHT,
                    })
                    console.log(`errorWWWW>>>> ${err}`);
                });
        } else {
            console.log("error");
        }

    };

    return (
        <>
            <div className="mainBg">
                <div className="container">
                    <Header />
                    <ToastContainer />
                    <div className="MidSecBx">
                        <div className="homeRight buygspBx">
                            <div className="ConnetBx">
                                <div className="signupHd">
                                    <span>Connect Wallet</span>
                                </div>

                                <div className="buyBx">
                                    <label>Email</label>
                                    <div className="gspFild">
                                        <div className="gspInn_Left">
                                            <Input
                                                type="text"
                                                name="email"
                                                id="exampleEmail"
                                                placeholder="Email"
                                                value={email}
                                                onChange={handleInputChange}
                                            />
                                        </div>


                                    </div>

                                    <span style={{ color: "#e307af" }}>{errors && errors.emailIdError ? <div><GoAlert /> {errors.emailIdError}</div> : ""}</span>
                                </div>

                                <div className="buyBx pb-65">
                                    <label>Password</label>
                                    <div className="gspFild">
                                        <div className="gspInn_Left">
                                            <Input
                                                type="password"
                                                name="password"
                                                id="exampleEmail"
                                                placeholder="Password"
                                                value={password}
                                                onChange={handleInputChange}
                                            />
                                        </div>

                                    </div>
                                    <span style={{ color: "#e307af" }}>{errors && errors.passwordError ? <div><GoAlert /> {errors.passwordError}</div> : ""}</span>
                                    <label className="signupButton pt-3">Doesn't have a wallet yet? <Link to="/signup">Create One</Link></label>
                                </div>

                                <div className="buygsp">
                                    <Button onClick={handleSubmit} disabled={isFlag}>Connect Wallet</Button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Login;
