import React from "react";




const Mission = () => {
  return (
    <>
        <div className="aboutOuter">
            <div className="container">
              <div className="aboutRight">
                <h2>about us</h2>
                <p>We are Leveraging the mass adoption of traditional games to build a truly decentralized Web3.0 gaming ecosystem.</p>
                <p>Gamesphere is the one-stop destination to truly experience the future of gaming. A decentralized skill-based ecosystem, where users can navigate and discover traditional games, forging the future of gameplay in Web 3.0.</p>
                <p>Gamesphere’s core idea revolves around incentivizing gamers, hosts, developers and game management systems for their active participation through challenges, reward pools, contests and NFT integration within its ecosystem of games.</p>
              </div>
            </div>
        </div>
    </>
  )
}

export default Mission