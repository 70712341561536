import React from 'react';
//Owl Carousel Libraries and Module
import OwlCarousel from 'react-owl-carousel';
import '../../../../node_modules/owl.carousel/dist/assets/owl.carousel.css';
import '../../../../node_modules/owl.carousel/dist/assets/owl.theme.default.css';

import SlideImg from "../../../assets/images/slide-img.png"
import Token from "../../../assets/images/Token.png"
import {AiOutlineHeart} from "react-icons/ai";

//Owl Carousel Settings
const options = {
  margin: 20,
  responsiveClass: true,
  nav: true,
  autoplay: false,
  navText: ["&larr;", "&rarr;"],
  smartSpeed: 1000,
  responsive: {
      0: {
          items: 1,
      },
      400: {
          items: 2,
      },
      768: {
          items: 3,
      },
      992: {
          items: 4,
      },
      1170: {
          items: 5,
      }
  },
};
class ItemSlider extends React.Component {
  render() {
    return (
        <div>
            <OwlCarousel className="slider-items owl-carousel" {...options}>
                <div class="item slideItemBx">
                    <div className='slideImg'>
                        <img src={SlideImg} alt="" />
                    </div>
                    <div className='slideTitle'>
                        <strong>GameSphere #12345</strong>
                        <span>Dark Render In The Pink</span>
                    </div>
                    <div className='likeOuter'>
                        <div className='likeLeft'>
                            <img src={Token} alt="" />
                            0.5
                        </div>
                        <div className='likeright'>
                            <AiOutlineHeart />
                            3.2k
                        </div>
                    </div>
                </div>
                <div class="item slideItemBx">
                    <div className='slideImg'>
                        <img src={SlideImg} alt="" />
                    </div>
                    <div className='slideTitle'>
                        <strong>GameSphere #12345</strong>
                        <span>Dark Render In The Pink</span>
                    </div>
                    <div className='likeOuter'>
                        <div className='likeLeft'>
                            <img src={Token} alt="" />
                            0.5
                        </div>
                        <div className='likeright'>
                            <AiOutlineHeart />
                            3.2k
                        </div>
                    </div>
                </div>
                <div class="item slideItemBx">
                    <div className='slideImg'>
                        <img src={SlideImg} alt="" />
                    </div>
                    <div className='slideTitle'>
                        <strong>GameSphere #12345</strong>
                        <span>Dark Render In The Pink</span>
                    </div>
                    <div className='likeOuter'>
                        <div className='likeLeft'>
                            <img src={Token} alt="" />
                            0.5
                        </div>
                        <div className='likeright'>
                            <AiOutlineHeart />
                            3.2k
                        </div>
                    </div>
                </div>
                <div class="item slideItemBx">
                    <div className='slideImg'>
                        <img src={SlideImg} alt="" />
                    </div>
                    <div className='slideTitle'>
                        <strong>GameSphere #12345</strong>
                        <span>Dark Render In The Pink</span>
                    </div>
                    <div className='likeOuter'>
                        <div className='likeLeft'>
                            <img src={Token} alt="" />
                            0.5
                        </div>
                        <div className='likeright'>
                            <AiOutlineHeart />
                            3.2k
                        </div>
                    </div>
                </div>
                <div class="item slideItemBx">
                    <div className='slideImg'>
                        <img src={SlideImg} alt="" />
                    </div>
                    <div className='slideTitle'>
                        <strong>GameSphere #12345</strong>
                        <span>Dark Render In The Pink</span>
                    </div>
                    <div className='likeOuter'>
                        <div className='likeLeft'>
                            <img src={Token} alt="" />
                            0.5
                        </div>
                        <div className='likeright'>
                            <AiOutlineHeart />
                            3.2k
                        </div>
                    </div>
                </div>
                <div class="item slideItemBx">
                    <div className='slideImg'>
                        <img src={SlideImg} alt="" />
                    </div>
                    <div className='slideTitle'>
                        <strong>GameSphere #12345</strong>
                        <span>Dark Render In The Pink</span>
                    </div>
                    <div className='likeOuter'>
                        <div className='likeLeft'>
                            <img src={Token} alt="" />
                            0.5
                        </div>
                        <div className='likeright'>
                            <AiOutlineHeart />
                            3.2k
                        </div>
                    </div>
                </div>
                <div class="item slideItemBx">
                    <div className='slideImg'>
                        <img src={SlideImg} alt="" />
                    </div>
                    <div className='slideTitle'>
                        <strong>GameSphere #12345</strong>
                        <span>Dark Render In The Pink</span>
                    </div>
                    <div className='likeOuter'>
                        <div className='likeLeft'>
                            <img src={Token} alt="" />
                            0.5
                        </div>
                        <div className='likeright'>
                            <AiOutlineHeart />
                            3.2k
                        </div>
                    </div>
                </div>
                <div class="item slideItemBx">
                    <div className='slideImg'>
                        <img src={SlideImg} alt="" />
                    </div>
                    <div className='slideTitle'>
                        <strong>GameSphere #12345</strong>
                        <span>Dark Render In The Pink</span>
                    </div>
                    <div className='likeOuter'>
                        <div className='likeLeft'>
                            <img src={Token} alt="" />
                            0.5
                        </div>
                        <div className='likeright'>
                            <AiOutlineHeart />
                            3.2k
                        </div>
                    </div>
                </div>
                <div class="item slideItemBx">
                    <div className='slideImg'>
                        <img src={SlideImg} alt="" />
                    </div>
                    <div className='slideTitle'>
                        <strong>GameSphere #12345</strong>
                        <span>Dark Render In The Pink</span>
                    </div>
                    <div className='likeOuter'>
                        <div className='likeLeft'>
                            <img src={Token} alt="" />
                            0.5
                        </div>
                        <div className='likeright'>
                            <AiOutlineHeart />
                            3.2k
                        </div>
                    </div>
                </div>
                <div class="item slideItemBx">
                    <div className='slideImg'>
                        <img src={SlideImg} alt="" />
                    </div>
                    <div className='slideTitle'>
                        <strong>GameSphere #12345</strong>
                        <span>Dark Render In The Pink</span>
                    </div>
                    <div className='likeOuter'>
                        <div className='likeLeft'>
                            <img src={Token} alt="" />
                            0.5
                        </div>
                        <div className='likeright'>
                            <AiOutlineHeart />
                            3.2k
                        </div>
                    </div>
                </div>
                <div class="item slideItemBx">
                    <div className='slideImg'>
                        <img src={SlideImg} alt="" />
                    </div>
                    <div className='slideTitle'>
                        <strong>GameSphere #12345</strong>
                        <span>Dark Render In The Pink</span>
                    </div>
                    <div className='likeOuter'>
                        <div className='likeLeft'>
                            <img src={Token} alt="" />
                            0.5
                        </div>
                        <div className='likeright'>
                            <AiOutlineHeart />
                            3.2k
                        </div>
                    </div>
                </div>
                <div class="item slideItemBx">
                    <div className='slideImg'>
                        <img src={SlideImg} alt="" />
                    </div>
                    <div className='slideTitle'>
                        <strong>GameSphere #12345</strong>
                        <span>Dark Render In The Pink</span>
                    </div>
                    <div className='likeOuter'>
                        <div className='likeLeft'>
                            <img src={Token} alt="" />
                            0.5
                        </div>
                        <div className='likeright'>
                            <AiOutlineHeart />
                            3.2k
                        </div>
                    </div>
                </div>
            </OwlCarousel>
        </div>
    )
    };
}
export default ItemSlider;