import React, { useState, useEffect } from "react";
import { Button, Input } from "reactstrap";
import Header from "../../common-components/header/header";
import TokanImg from "../../../assets/images/Token_icon.png";
import GspIcon1 from "../../../assets/images/polygon_logo.png";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { transactionCreate } from "../../../redux/action/transactionAction";
import { GoAlert } from "react-icons/go";
import "./buy-gsp.scss";
import { Link } from "react-router-dom";
import ReactLoading from 'react-loading';
import { userDetail } from "../../../redux/action/userAction";
const initialState = {
  gspbalance: "",
  bnbbalance: "",
  isFlag: false,
  errors: {},
};

const Buygspbalance = () => {
  const [iState, updateState] = useState(initialState);
  const [minValue, setMinvalue] = useState('');
  const dispatch = useDispatch();
  const { gspbalance, bnbbalance, isFlag, errors } = iState;
  const userDetails = useSelector((state) => state.users);
  const { userData } = userDetails;

  useEffect(() => {
    dispatch(userDetail());
  }, [userDetail]);

  const handleInputChange = (e) => {
    let gspbalanceError = "";
    const { name, value } = e.target;
    if (name === "gspbalance") {
      let bnb = (value / Number(userData.data && userData.data.tokenPrice));
      if (value < 2) {
        setMinvalue('GSP Token Value Should Be Greater Than 1')
      } else {
        setMinvalue("")
        updateState({
          bnbbalance: bnb,
        });
      }

      if (bnb > Number(userData.data && userData.data.ethBalance)) {
        gspbalanceError = "You Do Not Have Enough BNB In Your Wallet.";
        updateState({
          errors: { gspbalanceError },
          bnbbalance: bnb,
        });

      } else {
        updateState({
          ...iState,
          [name]: value,
          bnbbalance: bnb,
          errors: { gspbalanceError: "" },
        });
      }
    } else if (name === "bnbbalance") {
      let gsp = value * Number(userData.data && userData.data.tokenPrice);
      if (gsp < 2) {
        setMinvalue('GSP Token Value Should Be Greater Than 1')
      } else {
        setMinvalue("")
      }
      if (value > Number(userData.data && userData.data.ethBalance)) {
        gspbalanceError = "You Do Not Have Enough BNB In Your Wallet.";
        updateState({
          errors: { gspbalanceError },
          gspbalance: gsp,
        });
      } else {
        updateState({
          ...iState,
          [name]: value,
          gspbalance: gsp,
          errors: { gspbalanceError: "" },
        });
      }
    }
  };
  const maxValue = () => {
    updateState({
      ...iState,
      bnbbalance: Number(userData.data && userData.data.ethBalance),
      gspbalance: (Number(userData.data && userData.data.ethBalance) * Number(userData.data && userData.data.tokenPrice))
    });

  }
  const handleValidation = () => {
    let gspbalanceError = "";
    let bnbbalanceError = "";
    let formIsValid = true;

    if (!gspbalance) {
      gspbalanceError = "Please Enter Value.";
      formIsValid = false;
    }
    if (!bnbbalance) {
      bnbbalanceError = "Please Enter Value.";
      formIsValid = false;
    }
    updateState({
      ...iState,
      errors: { gspbalanceError, bnbbalanceError },
    });
    return formIsValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formIsValid = handleValidation();
    if (formIsValid) {
      updateState({
        isFlag: true
      });
      let data = { bnbValue: bnbbalance, gspValue: gspbalance };
      dispatch(transactionCreate(data))
        .then((res) => {
          if (res.code == 200) {
            updateState({
              isFlag: false,
              gspbalance: "",
              bnbbalance: "",
            });
            toast.success(res.message, {
              position: toast.POSITION.TOP_RIGHT,
            })
            dispatch(userDetail())
          } else {
            console.log("error");
            toast.error(res.data, {
              position: toast.POSITION.TOP_RIGHT,
            })
          }
        })
        .catch((err) => {
          console.log(`error>>>> ${err}`);
        });

    }
  };

  return (
    <>
      <div className="mainBg">
        <div className="container">
          <Header />
          <ToastContainer />



          <div className="MidSecBx">
            <div className="homeRight buygspBx">
              <div className="ConnetBx">
                <div className="notConnect_Outer">
                  <span>{userData.data && userData.data.gspBalance} GSP</span>
                </div>
                <div className="buyBx">
                  <label>Buy</label>
                  <div className="gspFild">
                    <div className="gspInn_Left">
                      <Input
                        type="number"
                        name="gspbalance"
                        id="exampleEmail"
                        placeholder="0.00"
                        value={gspbalance}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="gspInn_Right">
                      <i>
                        <img src={TokanImg} alt="" />
                      </i>
                      <small>GSP</small>
                    </div>
                  </div>
                </div>
                <div className="buyBx pb-65">
                  <label>It Will Cost Me</label>
                  <div className="gspFild">
                    <div className="gspInn_Left">
                      <Input
                        type="number"
                        name="bnbbalance"
                        id="exampleEmail"
                        placeholder="0.00"
                        value={bnbbalance}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="gspInn_Right" onClick={maxValue}>
                      <Link><span>MAX</span></Link>
                      <i>
                        <img src={GspIcon1} alt="" />
                      </i>
                      <small>Matic</small>
                    </div>
                  </div>
                </div>
                <div className="Inscont"> {errors && (
                  <span style={{ color: "#e307af" }}>{errors.gspbalanceError ? <div><GoAlert /> {errors.gspbalanceError}</div> : ""}</span>
                )}
                  <span style={{ color: "#e307af" }}>{minValue ? <div><GoAlert /> {minValue}</div> : ""}</span></div>
                <div className="buygsp">
                  <Button onClick={handleSubmit} disabled={isFlag}>BUY $GSP</Button>
                </div>
              </div>
              {
                isFlag ? <div className="loaderOuter" >
                  <ReactLoading type="bars" className="loaderInn" />
                </div> : ""
              }

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Buygspbalance;
