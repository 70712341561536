import React from "react";
import "./App.scss";
import { ToastContainer, toast } from "react-toastify";
import Layout from "./layout/layout";

const App = () => {

  return (
    <div>
      <ToastContainer/>
      <Layout />
    </div>
  );
};

export default App;
