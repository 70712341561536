import React from "react";
import EcoIson1 from '../../../assets/images/eco-icon1.png'
import EcoIson2 from '../../../assets/images/eco-icon2.png'
import EcoIson3 from '../../../assets/images/eco-icon3.png'
import EcoIson4 from '../../../assets/images/eco-icon4.png'
import Ecoshape from '../../../assets/images/ecoshape.png'


const Ecosystem = () => {
  return (
    <>
        <div className="ecosystem">
            <div className="eco_container">
                <h2 className="ecoHd">THE $GSP TOKEN ECOSYSTEM</h2>
                <div className="ecolistOuter web">
                    <div className="ecoList1">
                        <div className="ecoBx1">
                            <small>the marketplace will have a secondary market where users can buy or sell nfts for the games they play.</small>
                            <strong>nft marketplace trading</strong>
                            <i><img src={EcoIson1} alt="" /></i>
                        </div>
                        <div className="ecoBx1">
                            <small>Game studios will be able to launch their next web3 titles with grants from the community while having an already established fanbase.</small>
                            <strong>Web 3.0 gaming grants</strong>
                            <i><img src={EcoIson2} alt="" /></i>
                        </div>
                        <div className="ecoBx1">
                            <small>Developers, will be incentivized to keep supporting games after they launch, with token grants.</small>
                            <strong>Developer rewards</strong>
                            <i><img src={EcoIson3} alt="" /></i>
                        </div>
                        <div className="ecoBx1">
                            <small>A membership pass, will allow the players to earn unique benefits and play the newest games earlier.</small>
                            <strong>GAMESPHERE MEMBERSHIP PASS</strong>
                            <i><img src={EcoIson4} alt="" /></i>
                        </div>
                    </div>
                    <div className="ecoshape">
                        <img src={Ecoshape} alt="" />
                    </div>
                    <div className="ecoList1">
                        <div className="ecoBx2">
                            <i><img src={EcoIson4} alt="" /></i>
                            <strong>WEB 3.0 PARTNERSHIPS</strong>
                            <small>GAMESPHERE’S ECOSYSTEM WILL ALLOW GAME STUDIOS TO PARTNER WITH OTHER WEB 3.0 COMPANIES.</small>
                        </div>
                        <div className="ecoBx2">
                            <i><img src={EcoIson3} alt="" /></i>
                            <strong>USER<br/>ONBOARDING</strong>
                            <small>USER ONBOARDING WILL BE ASSISTED BY GAMESPHERE, TO HELP USERS NAVIGATE THE WEB 3.0 ECOSYSTEM.</small>
                        </div>
                        <div className="ecoBx2">
                            <i><img src={EcoIson2} alt="" /></i>
                            <strong>PAYMENT SETTLEMENTS</strong>
                            <small>$GSP WILL BE USED FOR PAYMENTS WITHIN THE ECOSYSTEM BECAUSE OF THE QUICK SETTLEMENT TIMES WITH LOW FEES.</small>
                        </div>
                        <div className="ecoBx2">
                            <i><img src={EcoIson1} alt="" /></i>
                            <strong>True Ownership<br/>of in-game<br/>assets</strong>
                            <small>NFTS OFFER GAMERS TO ACTUALLY OWN THE IN-GAME ITEMS THEY PURCHASE WITH THE POTENTIAL TO SELL THEM LATER.</small>
                        </div>
                    </div>
                </div>
                <div className="ecolistOuter mob">
                    <div className="ecoList1">
                        <div className="ecoBx1">
                            <i><img src={EcoIson1} alt="" /></i>
                            <strong>nft marketplace trading</strong>
                            <small>the marketplace will have a secondary market where users can buy or sell nfts for the games they play.</small>
                        </div>
                        <div className="ecoBx1">
                            <i><img src={EcoIson2} alt="" /></i>
                            <strong>Web 3.0 gaming grants</strong>
                            <small>Game studios will be able to launch their next web3 titles with grants from the community while having an already established fanbase.</small>
                        </div>
                        <div className="ecoBx1">
                            <i><img src={EcoIson3} alt="" /></i>
                            <strong>Developer rewards</strong>
                            <small>Developers, will be incentivized to keep supporting games after they launch, with token grants.</small>                           
                        </div>
                        <div className="ecoBx1">
                            <i><img src={EcoIson4} alt="" /></i>
                            <strong>GAMESPHERE MEMBERSHIP PASS</strong>
                            <small>A membership pass, will allow the players to earn unique benefits and play the newest games earlier.</small>
                        </div>
                        <div className="ecoBx1">
                            <i><img src={EcoIson4} alt="" /></i>
                            <strong>WEB 3.0 PARTNERSHIPS</strong>
                            <small>GAMESPHERE’S ECOSYSTEM WILL ALLOW GAME STUDIOS TO PARTNER WITH OTHER WEB 3.0 COMPANIES.</small>
                        </div>
                        <div className="ecoBx1">
                            <i><img src={EcoIson3} alt="" /></i>
                            <strong>USER <br/>ONBOARDING</strong>
                            <small>USER ONBOARDING WILL BE ASSISTED BY GAMESPHERE, TO HELP USERS NAVIGATE THE WEB 3.0 ECOSYSTEM.</small>
                        </div>
                        <div className="ecoBx1">
                            <i><img src={EcoIson2} alt="" /></i>
                            <strong>PAYMENT SETTLEMENTS</strong>
                            <small>$GSP WILL BE USED FOR PAYMENTS WITHIN THE ECOSYSTEM BECAUSE OF THE QUICK SETTLEMENT TIMES WITH LOW FEES.</small>
                        </div>
                        <div className="ecoBx1">
                            <i><img src={EcoIson1} alt="" /></i>
                            <strong>True Ownership<br/>of in-game<br/>assets</strong>
                            <small>NFTS OFFER GAMERS TO ACTUALLY OWN THE IN-GAME ITEMS THEY PURCHASE WITH THE POTENTIAL TO SELL THEM LATER.</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Ecosystem