import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, FormGroup, Label, Input } from 'reactstrap';
import { CAccordion, CAccordionBody, CAccordionHeader, CAccordionItem } from '@coreui/react'
import Header from '../../common-components/header/header'
import { BsSearch } from "react-icons/bs"
import { BiRefresh } from "react-icons/bi"
import FootLogo from "../../../assets/images/footer-logo.png"
import SocialIcon1 from "../../../assets/images/social-icon1.png"
import SocialIcon2 from "../../../assets/images/social-icon2.png"
import SocialIcon3 from "../../../assets/images/social-icon3.png"
import SocialIcon4 from "../../../assets/images/social-icon4.png"
import SocialIcon5 from "../../../assets/images/social-icon5.png"

import SlideImg from "../../../assets/images/slide-img.png"
import Token from "../../../assets/images/Token.png"
import { AiOutlineHeart } from "react-icons/ai";
import FltrImg1 from "../../../assets/images/fltrImg1.png"
import FltrImg2 from "../../../assets/images/fltrImg2.png"



import "./all-nfts.scss"


const AllNft = () => {

    const [year, setYear] = useState('')
    useEffect(() => {
        const d = new Date();
        let getYear = d.getFullYear();
        setYear(getYear)
    }, [])

    return (
        <>
            <div className='markeplaceBg'>
                <div className='container'>
                    <Header />
                    <div className='marketplace_Inn'>
                        <div className="marketplaceHd">
                            <h2>All NFTS</h2>
                        </div>
                        <div className="nftSrchOuter">
                            <div className="nftSrchBx">
                                <Input type="text" name="name" className="nftInp" placeholder="Search by NFTs or Game..." />
                                <Button><BsSearch /></Button>
                            </div>
                            <div className="nftlist">
                                <Input type="select" name="select" id="exampleSelect">
                                    <option>Trending</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                </Input>
                            </div>
                            <div className="nftrefresh">
                                <Button><BiRefresh /></Button>
                            </div>
                        </div>
                        <div className="allnftOuter">
                            <div className="filterLeft">
                                <div className="fltHd">Filter</div>
                                <div className="accordialBx">
                                    <CAccordion flush>
                                        <CAccordionItem itemKey={1}>
                                            <CAccordionHeader>Games</CAccordionHeader>
                                            <CAccordionBody>
                                                <FormGroup check>
                                                    <Label check>
                                                        <Input type="checkbox" />
                                                        <i><img src={FltrImg1} alt='' /></i>
                                                        Aspect
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup check>
                                                    <Label check>
                                                        <Input type="checkbox" />
                                                        <i><img src={FltrImg2} alt='' /></i>
                                                        Kraken
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup check>
                                                    <Label check>
                                                        <Input type="checkbox" />
                                                        <i><img src={FltrImg1} alt='' /></i>
                                                        Bender
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup check>
                                                    <Label check>
                                                        <Input type="checkbox" />
                                                        <i><img src={FltrImg2} alt='' /></i>
                                                        Lynch
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup check>
                                                    <Label check>
                                                        <Input type="checkbox" />
                                                        <i><img src={FltrImg1} alt='' /></i>
                                                        Big Papa
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup check>
                                                    <Label check>
                                                        <Input type="checkbox" />
                                                        <i><img src={FltrImg2} alt='' /></i>
                                                        Mad Dog
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup check>
                                                    <Label check>
                                                        <Input type="checkbox" />
                                                        <i><img src={FltrImg1} alt='' /></i>
                                                        Bowser
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup check>
                                                    <Label check>
                                                        <Input type="checkbox" />
                                                        <i><img src={FltrImg2} alt='' /></i>
                                                        O'Doyle
                                                    </Label>
                                                </FormGroup>
                                            </CAccordionBody>
                                        </CAccordionItem>
                                        <CAccordionItem itemKey={2}>
                                            <CAccordionHeader>Status</CAccordionHeader>
                                            <CAccordionBody>
                                                <FormGroup check>
                                                    <Label check>
                                                        <Input type="checkbox" />
                                                        Buy Now
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup check>
                                                    <Label check>
                                                        <Input type="checkbox" />
                                                        On Auction
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup check>
                                                    <Label check>
                                                        <Input type="checkbox" />
                                                        New
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup check>
                                                    <Label check>
                                                        <Input type="checkbox" />
                                                        Has Offers
                                                    </Label>
                                                </FormGroup>
                                            </CAccordionBody>
                                        </CAccordionItem>
                                    </CAccordion>
                                </div>
                            </div>
                            <div className="listingRight">
                                <div className="listOutBx">
                                    <div class="slideItemBx">
                                        <div className='slideImg'>
                                            <Link to="/nfts-details">
                                                <img src={SlideImg} alt="" />
                                            </Link>
                                        </div>
                                        <div className='slideTitle'>
                                            <strong>GameSphere #12345</strong>
                                            <span>Dark Render In The Pink</span>
                                        </div>
                                        <div className='likeOuter'>
                                            <div className='likeLeft'>
                                                <img src={Token} alt="" />
                                                0.5
                                            </div>
                                            <div className='likeright'>
                                                <AiOutlineHeart />
                                                3.2k
                                            </div>
                                        </div>
                                    </div>
                                    <div class="slideItemBx">
                                        <div className='slideImg'>
                                            <Link to="/nfts-details">
                                                <img src={SlideImg} alt="" />
                                            </Link>
                                        </div>
                                        <div className='slideTitle'>
                                            <strong>GameSphere #12345</strong>
                                            <span>Dark Render In The Pink</span>
                                        </div>
                                        <div className='likeOuter'>
                                            <div className='likeLeft'>
                                                <img src={Token} alt="" />
                                                0.5
                                            </div>
                                            <div className='likeright'>
                                                <AiOutlineHeart />
                                                3.2k
                                            </div>
                                        </div>
                                    </div>
                                    <div class="slideItemBx">
                                        <div className='slideImg'>
                                            <Link to="/nfts-details">
                                                <img src={SlideImg} alt="" />
                                            </Link>
                                        </div>
                                        <div className='slideTitle'>
                                            <strong>GameSphere #12345</strong>
                                            <span>Dark Render In The Pink</span>
                                        </div>
                                        <div className='likeOuter'>
                                            <div className='likeLeft'>
                                                <img src={Token} alt="" />
                                                0.5
                                            </div>
                                            <div className='likeright'>
                                                <AiOutlineHeart />
                                                3.2k
                                            </div>
                                        </div>
                                    </div>
                                    <div class="slideItemBx">
                                        <div className='slideImg'>
                                            <Link to="/nfts-details">
                                                <img src={SlideImg} alt="" />
                                            </Link>
                                        </div>
                                        <div className='slideTitle'>
                                            <strong>GameSphere #12345</strong>
                                            <span>Dark Render In The Pink</span>
                                        </div>
                                        <div className='likeOuter'>
                                            <div className='likeLeft'>
                                                <img src={Token} alt="" />
                                                0.5
                                            </div>
                                            <div className='likeright'>
                                                <AiOutlineHeart />
                                                3.2k
                                            </div>
                                        </div>
                                    </div>
                                    <div class="slideItemBx">
                                        <div className='slideImg'>
                                            <Link to="/nfts-details">
                                                <img src={SlideImg} alt="" />
                                            </Link>
                                        </div>
                                        <div className='slideTitle'>
                                            <strong>GameSphere #12345</strong>
                                            <span>Dark Render In The Pink</span>
                                        </div>
                                        <div className='likeOuter'>
                                            <div className='likeLeft'>
                                                <img src={Token} alt="" />
                                                0.5
                                            </div>
                                            <div className='likeright'>
                                                <AiOutlineHeart />
                                                3.2k
                                            </div>
                                        </div>
                                    </div>

                                    <div class="slideItemBx">
                                        <div className='slideImg'>
                                            <Link to="/nfts-details">
                                                <img src={SlideImg} alt="" />
                                            </Link>
                                        </div>
                                        <div className='slideTitle'>
                                            <strong>GameSphere #12345</strong>
                                            <span>Dark Render In The Pink</span>
                                        </div>
                                        <div className='likeOuter'>
                                            <div className='likeLeft'>
                                                <img src={Token} alt="" />
                                                0.5
                                            </div>
                                            <div className='likeright'>
                                                <AiOutlineHeart />
                                                3.2k
                                            </div>
                                        </div>
                                    </div>
                                    <div class="slideItemBx">
                                        <div className='slideImg'>
                                            <Link to="/nfts-details">
                                                <img src={SlideImg} alt="" />
                                            </Link>
                                        </div>
                                        <div className='slideTitle'>
                                            <strong>GameSphere #12345</strong>
                                            <span>Dark Render In The Pink</span>
                                        </div>
                                        <div className='likeOuter'>
                                            <div className='likeLeft'>
                                                <img src={Token} alt="" />
                                                0.5
                                            </div>
                                            <div className='likeright'>
                                                <AiOutlineHeart />
                                                3.2k
                                            </div>
                                        </div>
                                    </div>
                                    <div class="slideItemBx">
                                        <div className='slideImg'>
                                            <Link to="/nfts-details">
                                                <img src={SlideImg} alt="" />
                                            </Link>
                                        </div>
                                        <div className='slideTitle'>
                                            <strong>GameSphere #12345</strong>
                                            <span>Dark Render In The Pink</span>
                                        </div>
                                        <div className='likeOuter'>
                                            <div className='likeLeft'>
                                                <img src={Token} alt="" />
                                                0.5
                                            </div>
                                            <div className='likeright'>
                                                <AiOutlineHeart />
                                                3.2k
                                            </div>
                                        </div>
                                    </div>
                                    <div class="slideItemBx">
                                        <div className='slideImg'>
                                            <Link to="/nfts-details">
                                                <img src={SlideImg} alt="" />
                                            </Link>
                                        </div>
                                        <div className='slideTitle'>
                                            <strong>GameSphere #12345</strong>
                                            <span>Dark Render In The Pink</span>
                                        </div>
                                        <div className='likeOuter'>
                                            <div className='likeLeft'>
                                                <img src={Token} alt="" />
                                                0.5
                                            </div>
                                            <div className='likeright'>
                                                <AiOutlineHeart />
                                                3.2k
                                            </div>
                                        </div>
                                    </div>
                                    <div class="slideItemBx">
                                        <div className='slideImg'>
                                            <Link to="/nfts-details">
                                                <img src={SlideImg} alt="" />
                                            </Link>
                                        </div>
                                        <div className='slideTitle'>
                                            <strong>GameSphere #12345</strong>
                                            <span>Dark Render In The Pink</span>
                                        </div>
                                        <div className='likeOuter'>
                                            <div className='likeLeft'>
                                                <img src={Token} alt="" />
                                                0.5
                                            </div>
                                            <div className='likeright'>
                                                <AiOutlineHeart />
                                                3.2k
                                            </div>
                                        </div>
                                    </div>
                                    <div class="slideItemBx">
                                        <div className='slideImg'>
                                            <Link to="/nfts-details">
                                                <img src={SlideImg} alt="" />
                                            </Link>
                                        </div>
                                        <div className='slideTitle'>
                                            <strong>GameSphere #12345</strong>
                                            <span>Dark Render In The Pink</span>
                                        </div>
                                        <div className='likeOuter'>
                                            <div className='likeLeft'>
                                                <img src={Token} alt="" />
                                                0.5
                                            </div>
                                            <div className='likeright'>
                                                <AiOutlineHeart />
                                                3.2k
                                            </div>
                                        </div>
                                    </div>
                                    <div class="slideItemBx">
                                        <div className='slideImg'>
                                            <Link to="/nfts-details">
                                                <img src={SlideImg} alt="" />
                                            </Link>
                                        </div>
                                        <div className='slideTitle'>
                                            <strong>GameSphere #12345</strong>
                                            <span>Dark Render In The Pink</span>
                                        </div>
                                        <div className='likeOuter'>
                                            <div className='likeLeft'>
                                                <img src={Token} alt="" />
                                                0.5
                                            </div>
                                            <div className='likeright'>
                                                <AiOutlineHeart />
                                                3.2k
                                            </div>
                                        </div>
                                    </div>
                                    <div class="slideItemBx">
                                        <div className='slideImg'>
                                            <Link to="/nfts-details">
                                                <img src={SlideImg} alt="" />
                                            </Link>
                                        </div>
                                        <div className='slideTitle'>
                                            <strong>GameSphere #12345</strong>
                                            <span>Dark Render In The Pink</span>
                                        </div>
                                        <div className='likeOuter'>
                                            <div className='likeLeft'>
                                                <img src={Token} alt="" />
                                                0.5
                                            </div>
                                            <div className='likeright'>
                                                <AiOutlineHeart />
                                                3.2k
                                            </div>
                                        </div>
                                    </div>
                                    <div class="slideItemBx">
                                        <div className='slideImg'>
                                            <Link to="/nfts-details">
                                                <img src={SlideImg} alt="" />
                                            </Link>
                                        </div>
                                        <div className='slideTitle'>
                                            <strong>GameSphere #12345</strong>
                                            <span>Dark Render In The Pink</span>
                                        </div>
                                        <div className='likeOuter'>
                                            <div className='likeLeft'>
                                                <img src={Token} alt="" />
                                                0.5
                                            </div>
                                            <div className='likeright'>
                                                <AiOutlineHeart />
                                                3.2k
                                            </div>
                                        </div>
                                    </div>
                                    <div class="slideItemBx">
                                        <div className='slideImg'>
                                            <Link to="/nfts-details">
                                                <img src={SlideImg} alt="" />
                                            </Link>
                                        </div>
                                        <div className='slideTitle'>
                                            <strong>GameSphere #12345</strong>
                                            <span>Dark Render In The Pink</span>
                                        </div>
                                        <div className='likeOuter'>
                                            <div className='likeLeft'>
                                                <img src={Token} alt="" />
                                                0.5
                                            </div>
                                            <div className='likeright'>
                                                <AiOutlineHeart />
                                                3.2k
                                            </div>
                                        </div>
                                    </div>
                                    <div class="slideItemBx">
                                        <div className='slideImg'>
                                            <Link to="/nfts-details">
                                                <img src={SlideImg} alt="" />
                                            </Link>
                                        </div>
                                        <div className='slideTitle'>
                                            <strong>GameSphere #12345</strong>
                                            <span>Dark Render In The Pink</span>
                                        </div>
                                        <div className='likeOuter'>
                                            <div className='likeLeft'>
                                                <img src={Token} alt="" />
                                                0.5
                                            </div>
                                            <div className='likeright'>
                                                <AiOutlineHeart />
                                                3.2k
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="moreList">
                                    <Button>Load more</Button>
                                </div>
                            </div>
                        </div>
                        <div className="homeFooter mpFoot">

                            <i><img src={FootLogo} alt="" /></i>
                            <span>GAMESPHERE TECHNOLOGY LLC</span>
                            <a href="https://www.facebook.com/gamesphere.io" target="_blank"><img src={SocialIcon1} alt="" /></a>
                            <a href="https://t.me/GameSphereIO" target="_blank"><img src={SocialIcon2} alt="" /></a>
                            <a href="https://www.instagram.com/gamesphere_io/" target="_blank"><img src={SocialIcon3} alt="" /></a>
                            <a href="https://discord.com/invite/27WgyZKN" target="_blank"><img src={SocialIcon4} alt="" /></a>
                            <a href="https://www.linkedin.com/company/gamesphere-io/" target="_blank"><img src={SocialIcon5} alt="" /></a>
                            <span>Gamesphere technology llc {year} &copy; All right reserved.</span>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )

}

export default AllNft

