import React, { Component } from 'react';
import { Collapse, Button, CardBody, Card } from 'reactstrap';
 import { VscThreeBars } from "react-icons/vsc";
import { Link} from 'react-router-dom';


class Example extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = { collapse: false };
  }

  toggle() {
    this.setState({ collapse: !this.state.collapse });
  }

  render() {
    return (
      <div className='navOuter'>
        <Button className='Navbtn' onClick={this.toggle}><VscThreeBars /></Button>
        <Collapse className='NavListOuter' isOpen={this.state.collapse}>
          <Card>
            <CardBody>
                <Button className='NavClose' onClick={this.toggle}>X</Button>
                <div className='NavList'>
                    <ul>
                        <li>
                            <Link to='/'>HOME</Link>
                        </li>
                        <li>
                            <Link to='/gsp-balance'>GSP BALANCE</Link>
                        </li>
                        <li>
                            <Link to='/buy-gsp'>BUY TOKEN</Link>
                        </li>
                        <li>
                            <Link to='/transaction'>TRANSACTIONS</Link>
                        </li>
                        <li>
                            <Link to='/game'>GAMES</Link>
                        </li>
                        <li>
                            <Link to='/my-nft'>MY NFT</Link>
                        </li>
                        <li>
                            <Link to='/all-nft'>All NFT</Link>
                        </li>
                       
                    </ul>
                    <div className='mobnavBtns'>
                      <Button><a href="../../../assets/images/GameSphere-whitepaper.pdf" target="_blank">Whitepaper</a></Button>
                      <Button>
                        <a href="https://linktr.ee/gamesphere" target="_blank">Join The Community</a>
                      </Button>
                    </div>
                </div>
                
            </CardBody>
          </Card>
        </Collapse>
      </div>
    );
  }
}

export default Example;