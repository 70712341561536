import React, { useState, useEffect } from "react";
import { Button } from 'reactstrap';
import Header from "../../common-components/header/header";
import Icon1 from "../../../assets/images/Token_icon.png";
import NoData from "../../../assets/images/nodata.png"
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from 'react-paginate';
import { transactionList } from "../../../redux/action/transactionAction";
import { getUsdPrice, getUsdPriceList } from "../../../redux/action/usdPriceAction";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import "./transaction.scss";
import { BiSearch } from "react-icons/bi";
import { FaCalendarAlt } from "react-icons/fa"
import { TbRefresh } from "react-icons/tb"
import { userDetail } from "../../../redux/action/userAction";

const Transaction = () => {
  const dispatch = useDispatch();

  const [search, setSearch] = useState('')
  const listData = useSelector((state) => state.transaction);
  const [perPage, SetcurrentPage] = useState(10)
  const { loader, transactionListData } = listData;
  const usdPrice = useSelector((state) => state.gspUsdPrice);
  const { usdPriceData, usdPriceList } = usdPrice;
  const userDetails = useSelector((state) => state.users);
  const { userData } = userDetails;
  const usdPrices = Number(usdPriceData?.data?.price) || 0;
  const gspBalance = Number(userData?.data?.gspBalance) || 0;
  useEffect(() => {
    dispatch(userDetail());
  }, [userDetail]);
  let countData = transactionListData && (transactionListData.data && transactionListData.data[0].total.length > 0) ? transactionListData.data[0].total[0].count / 10 : []

  useEffect(() => {
    let obj = {
      address: "",
      pageNumber: 1,
      searchVal: ""
    }
    dispatch(transactionList(obj));
  }, [transactionList]);



  useEffect(() => {
    dispatch(getUsdPrice());
    dispatch(getUsdPriceList());
  }, []);

  const handlePageChange = (e) => {
    const selectedPage = e.selected + 1
    SetcurrentPage(10 * selectedPage)
    let obj = {
      address: "",
      pageNumber: selectedPage,
      searchVal: ""
    }

    dispatch(transactionList(obj))

  }
  const handleCallback = (start, end) => {
    let fromDate = moment(start._d).format("YYYY-MM-DD")
    let toDate = moment(end._d).format("YYYY-MM-DD")
    let obj = { address: "", searchVal: '', pageNumber: 1, fromDate, toDate }
    dispatch(transactionList(obj));
  }
  const handleEvent = (event, picker) => {
    console.log(picker.startDate);
  }

  const handleSearch = (e) => {
    const { name, value } = e.target;
    setSearch(value)
    let obj = { address: "", searchVal: value, pageNumber: 1 }
    dispatch(transactionList(obj));
  }

  const refreshPage = () => {
    let obj = { address: "", searchVal: '', pageNumber: 1 }
    dispatch(transactionList(obj));
    setSearch("")
  }

  return (
    <>
      <div className="gameBg">
        <div className="container">
          <Header />
          <div className="MidSecInner mb-5">
            <div className="trans_graph">
              <div className="totalValBx transValueBx">
                <div className="totlValInn">
                  <label>USD Total</label>
                  <strong>${(usdPrices * gspBalance).toFixed(4)}</strong>
                </div>
                <div className="totlValInn">
                  <label>$ GSP Balance</label>
                  <span>
                    <img src={Icon1} alt="" /> {userData.data && userData.data.gspBalance}
                  </span>
                </div>
              </div>
              <div className="graphImg">
                <LineChart width={600} height={300} data={usdPriceList && usdPriceList.data && usdPriceList.data}>
                  <XAxis dataKey="time" />
                  <YAxis />
                  <CartesianGrid stroke="" strokeDasharray="5" />
                  <Line type="monotone" dataKey="price" stroke="#8884d8" />
                  <Tooltip />
                </LineChart>
                {/* <img src={GraphImg} alt="" /> */}
              </div>

            </div>
            <div className="transValue">
              <div className="transHdOuter">
                <div className="valueHd">Transactions</div>
                <div className="transFltr">
                  <div className="dateInpBx">
                    <DateRangePicker onEvent={handleEvent} onCallback={handleCallback}>
                      <input type="text" className="dateInp" />
                    </DateRangePicker>
                    <FaCalendarAlt />
                  </div>
                  <div className="srchInpBx">
                    <input
                      type="text"
                      name="gspbalance"
                      id="exampleEmail"
                      placeholder="search"
                      className="srchInp"
                      value={search} onChange={handleSearch}
                    />
                    <BiSearch />
                  </div>
                  <div className="refreshBtn">
                    <Button onClick={refreshPage}><TbRefresh /></Button>
                  </div>

                </div>
              </div>
              <div className="transtable">
                <table cellPadding="0" cellSpacing="0" border="0">
                <tr>
                    <th>Sr. No.</th>
                    <th>Balance</th>
                    <th>Balance</th>
                    <th>Transaction Type</th>
                    <th>Date</th>
                    <th>Status</th>
                  </tr>
                  {transactionListData.data &&
                    transactionListData.data[0].data.length > 0 ? (
                    transactionListData.data[0].data.map((data, i) => (
                      <tr key={i}>
                        <td>{i + 1 + (perPage - 10)}</td>
                        <td>{data.bnbValue.toFixed(4)} MATIC</td>
                        <td>{data.gspValue} GSP</td>
                        <td>{data.transactionType}</td>
                        <td>{moment(data.createdAt).format("DD/MM/YYYY")}</td>
                        <td>{data.status}</td>
                      </tr>
                    ))
                  ) : (
                    <tr> <td colspan="5" className="nodata">
                      <i><img src={NoData} alt="" /></i>
                      <strong></strong>
                    </td>
                    </tr>
                  )}
                </table>
              </div>

              <div className="paginationBx">
                <ReactPaginate
                  previousLabel={'<'}
                  nextLabel={'>'}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  pageCount={Math.ceil(countData)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageChange}
                  containerClassName={'pagination'}
                  activeClassName={'active'}
                />
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default Transaction;
