import React from "react";




const Mission = () => {
  return (
    <>
        <div className="missionOuter">
            <div className="container">
              <div className="missionLeft">
                <h2>Our mission</h2>
                <p>We strongly believe in true ownership of digital assets in the new era of gaming, where tokenized gaming currencies and blockchain-based games will revolutionize the industry. All players and developers should have the opportunity to earn from their skill in the games, and to be rewarded for contributing to the success of these games.</p>
                <ul>
                  <li>To recognize and reward players and developers skill, effort and loyalty.</li>
                  <li>To empower game developers and have their imagination in creating their games for the users inside a unified ecosystem.</li>
                  <li>To build a solid foundation for game studios to create their games within the GSP ecosystem. This will create an attraction for creators and users to buy or fund games using $GSP.</li>
                </ul>
              </div>
            </div>
        </div>
    </>
  )
}

export default Mission