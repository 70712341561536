import React from 'react';
import { Routes, Route } from "react-router-dom";
import Home from '../components/pages/home/home';
import Signup from '../components/pages/signup-user/signup';
import Login from '../components/pages/login/login';
import BuyGsp from '../components/pages/buy-gsp/buy-gsp';
import GspBalance from '../components/pages/gsp-balance/gsp-balance';
import Game from '../components/pages/game/game';
import Transaction from '../components/pages/transaction/transaction';
import Marketplace from '../components/pages/marketplace/marketplace';
import AllNfts from '../components/pages/all-nfts/all-nfts';
import MyNft from '../components/pages/my-nft/my-nft';
import NftsDetails from '../components/pages/nfts-details/nfts-details';
import GameTx from '../components/pages/game-transaction/game-transaction'

const Layout = () => {

    return (
        <Routes>
            {/* public access url */}
            <Route path="/" element={<Home />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/login" element={<Login />} />
            <Route path="/buy-gsp" element={<BuyGsp />} />
            <Route path="/gsp-balance" element={<GspBalance />} />
            <Route path="/game" element={<Game />} />
            <Route path="/transaction" element={<Transaction />} />
            <Route path='/marketplace' element={<Marketplace />} />
            <Route path='/all-nft' element={<AllNfts />} />
            <Route path='/my-nft' element={<MyNft />} />
            <Route path='nfts-details' element={<NftsDetails />} />
            <Route path='/game-transaction' element={<GameTx />} />
        </Routes>
    )
}
export default Layout