export const userAction = {
  USER_REGISTER_INITIATE: "USER_REGISTER_INITIATE",
  USER_REGISTER_SUCCESS: "USER_REGISTER_SUCCESS",
  USER_REGISTER_FAILURE: "USER_REGISTER_FAILURE",
  USER_LOGIN_INITIATE: "USER_LOGIN_INITIATE",
  USER_LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
  USER_LOGIN_FAILURE: "USER_LOGIN_FAILURE",
  USER_DETAIL_INITIATE: "USER_DETAIL_INITIATE",
  USER_DETAIL_SUCCESS: "USER_DETAIL_SUCCESS",
  USER_DETAIL_FAILURE: "USER_DETAIL_FAILURE",
};

export const transactionAction = {
  TRANSACTION_INITIATE: "TRANSACTION_INITIATE",
  TRANSACTION_SUCCESS: "TRANSACTION_SUCCESS",
  TRANSACTION_FAILURE: "TRANSACTION_FAILURE",
  TRANSACTION_LIST_INITIATE: "TRANSACTION_LIST_INITIATE",
  TRANSACTION_LIST_SUCCESS: "TRANSACTION_LIST_SUCCESS",
  TRANSACTION_LIST_FAILURE: "TRANSACTION_LIST_FAILURE",
};
export const usdPriceAction = {
  USD_PRICE_INITIATE: "USD_PRICE_INITIATE",
  USD_PRICE_SUCCESS: "USD_PRICE_SUCCESS",
  USD_PRICE_FAILURE: "USD_PRICE_FAILURE",
  USD_PRICE_LIST_INITIATE: "USD_PRICE_LIST_INITIATE",
  USD_PRICE_LIST_SUCCESS: "USD_PRICE_LIST_SUCCESS",
  USD_PRICE_LIST_FAILURE: "USD_PRICE_LIST_FAILURE",
};
