import { userAction } from "../actionType";

const initialState = {
    loader: false,
    userData: {},
};
export default function userReducer(state = initialState, { type, payload }) {
    switch (type) {
        case userAction.USER_DETAIL_INITIATE: {
            return {
                ...state,
                loader: true,
                userData: [],
            };
        }
        case userAction.USER_DETAIL_SUCCESS: {
            return {
                ...state,
                loader: false,
                userData: payload,
            };
        }
        case userAction.USER_DETAIL_FAILURE: {
            return {
                ...state,
                loader: false,
                userData: [],
            };
        }
        default:
            return state;
    }
}
