import { userAction } from '../actionType'
import config from '../../config/config'
import axios from 'axios'

//////////////////////////////// User Signup //////////////////////////////

export const userRegisterInitiate = () => ({
  type: userAction.USER_REGISTER_INITIATE,
})

export const userRegisterSuccess = (data) => ({
  type: userAction.USER_REGISTER_SUCCESS,
  payload: data,
})

export const userRegisterFailure = () => ({
  type: userAction.USER_REGISTER_FAILURE,
})

export function userRegister(payload) {
  return (dispatch) => {
    dispatch(userRegisterInitiate())
    return new Promise((resolve, reject) =>
      axios
        .post(`${config.Url}userRegister`, payload)
        .then((response) => {
          const data = response.data
          if (data.code && data.code == 200) {
            dispatch(userRegisterSuccess(data))
          } else {
            dispatch(userRegisterFailure(data))
          }
          resolve(data)
        })
        .catch((err) => {
          dispatch(userRegisterFailure(err))
          reject(err)
        }),
    )
  }
}

//////////////////////////////// User Login //////////////////////////////

export const userLoginInitiate = () => ({
  type: userAction.USER_LOGIN_INITIATE,
})

export const userLoginSuccess = (data) => ({
  type: userAction.USER_LOGIN_SUCCESS,
  payload: data,
})

export const userLoginFailure = () => ({
  type: userAction.USER_LOGIN_FAILURE,
})

export function userLogin(payload) {
  return (dispatch) => {
    dispatch(userLoginInitiate())
    return new Promise((resolve, reject) =>
      axios
        .post(`${config.Url}userLogin`, payload)
        .then((response) => {
          const data = response.data
          if (data.code && data.code == 200) {
            dispatch(userLoginSuccess(data))
          } else {
            dispatch(userLoginFailure(data))
          }
          resolve(data)
        })
        .catch((err) => {
          dispatch(userLoginFailure(err))
          reject(err)
        }),
    )
  }
}


//////////////////////////////// User Detail //////////////////////////////

export const userDetailInitiate = () => ({
  type: userAction.USER_DETAIL_INITIATE,
})

export const userDetailSuccess = (data) => ({
  type: userAction.USER_DETAIL_SUCCESS,
  payload: data,
})

export const userDetailFailure = () => ({
  type: userAction.USER_DETAIL_FAILURE,
})

export function userDetail(payload) {
  return (dispatch) => {
    dispatch(userDetailInitiate())
    return new Promise((resolve, reject) =>
     
      axios
      .get(`${config.Url}userDetail`, {
        headers: { token:localStorage.getItem('userToken') }
      })
        .then((response) => {
          const data = response.data
          if (data.code && data.code == 200) {
            dispatch(userDetailSuccess(data))
          } else {
            dispatch(userDetailFailure(data))
          }
          resolve(data)
        })
        .catch((err) => {
          dispatch(userDetailFailure(err))
          reject(err)
        }),
    )
  }
}
