import { transactionAction } from '../actionType'
import config from '../../config/config'
import axios from 'axios'


/**
 * CREATE TRANSACTION
 * @param object
 * @createdBy Rohit 
 */


export const transactionInitiate = () => ({
  type: transactionAction.TRANSACTION_INITIATE,
})

export const transactionSuccess = (data) => ({
  type: transactionAction.TRANSACTION_SUCCESS,
  payload: data,
})

export const transactionFailure = () => ({
  type: transactionAction.TRANSACTION_FAILURE,
})

export function transactionCreate(payload) {
  return (dispatch) => {
    dispatch(transactionInitiate())
    return new Promise((resolve, reject) =>
      axios
        .post(`${config.Url}transactionCreate`, payload, {
          headers: { token: localStorage.getItem('userToken') }
        })
        .then((response) => {
          const data = response.data
          if (data.code && data.code == 200) {
            dispatch(transactionSuccess(data))
          } else {
            dispatch(transactionFailure(data))
          }
          resolve(data)
        })
        .catch((err) => {
          dispatch(transactionFailure(err))
          reject(err)
        }),
    )
  }
}

/**
 * CREATE TRANSACTION
 * @param object
 * @createdBy Rohit 
 */

export const transactionListInitiate = () => ({
  type: transactionAction.TRANSACTION_LIST_INITIATE,
})

export const transactionListSuccess = (data) => ({
  type: transactionAction.TRANSACTION_LIST_SUCCESS,
  payload: data,
})

export const transactionListFailure = () => ({
  type: transactionAction.TRANSACTION_LIST_FAILURE,
})

export function transactionList(payload) {
  return (dispatch) => {
    let Fdate = payload.fromDate ? payload.fromDate : ''
    let Tdate = payload.toDate ? payload.toDate : ''
    dispatch(transactionListInitiate())
    return new Promise((resolve, reject) =>
      axios
        .get(`${config.Url}transactionList?from=${payload.address}&pageNumber=${payload.pageNumber}&fromDate=${Fdate}&toDate=${Tdate}&search=${payload.searchVal}`, {
          headers: { token: localStorage.getItem('userToken') }
        })
        .then((response) => {
          const data = response.data
          if (data.code && data.code == 200) {
            dispatch(transactionListSuccess(data))
          } else {
            dispatch(transactionListFailure(data))
          }
          resolve(data)
        })
        .catch((err) => {
          dispatch(transactionListFailure(err))
          reject(err)
        }),
    )
  }
}