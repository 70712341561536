import React, { useState, useEffect } from "react";
import { Accordion, AccordionItem } from '@szhsin/react-accordion';


const HowList = () => {
  return (
    <>
      <div className="howList_Outer">
        <h2>FAQ</h2>
        <div className="accordialList">
          <Accordion>
            <AccordionItem header="How do I connect to the GSP Wallet?">
              If you have connected over the WEB: Click on “Connect Wallet” below the GSP logo on the Landing Page or on the top right corner, once you click on “Connect Wallet” a list of 3rd party wallets will be provided you can choose from the options available and select accordi
              If you are connected on the Mobile App: Click on the “Connect Wallet” below the GSP Logo and it will allow you to connect to the Metamask wallet app and allow you to proceed to login
            </AccordionItem>
            <AccordionItem header="What happens if I loose access to my Web Wallet?">
              As your GSP Wallet is connected through a 3rd party wallet you need to follow the instructions of the selected 3rd party wallet at the time of registration.
            </AccordionItem>
            <AccordionItem header="How do I add GSP tokens to my Wallet?">
              Once you have successfully connected your wallet click on “Buy Tokens” enter the desired number of tokens and click on “Buy GSP” using BNB or Matic.
            </AccordionItem>
            <AccordionItem header="How can I use GSP tokens in my games?">
              Once you have successfully purchased GSP tokens you can view the list of games available in the GSP ecosystem, click on your choice of game and start playing by spending the GSP tokens.
            </AccordionItem>
            <AccordionItem header="What NFTs are available on GSP?">
              NFTs are unique digital assets created on the blockchain. They can be everything from gaming items and digital art, to sports collectibles and real-world assets.
              GSP is an Eco System of Games from Different Game Studios. These Game Studios publish their NFT's on our Platform and can be interoperable. You can visit the MarketPlace Link and Choose the Right NFT and buy the NFT through GSP$ Tokens
            </AccordionItem>
            <AccordionItem header=" Where can I find new games?">
              To know more about more about new games please join our community by clicking on our home page also join our social media pages to get more updates.
            </AccordionItem>
            <AccordionItem header=" Where is $GSP deployed?">
              GameSphere Platform is Deployed on 2 known Chains - Polygon and Binance.
            </AccordionItem>
            <AccordionItem header="What is GSP used for?">
              GSP can be used for several things
              <br/>
              a) Medium of Exchange:
              GSP$ is the medium of exchange in The GameSphere ecosystem.It will be necessary for making purchases from The GameSphere marketplace. It will allow you to purchase lives, power ups, skins and many more things required to enhance your gaming experience inside the ecosystem.
              <br/>
              b) Gaming Championship:
              GameSphere community members will be able to participate in the gaming championships with all the games in The GameSphere 's ecosystem.
              <br/>
              c) Play2Earn:
              Players can potentially earn some GSP$ by playing games.
              <br/>
              d) Selling your Assets:
              GSP$ allows you to trade and sell your assets like lives, skins, characters, power ups in the games to the other players within the ecosystem through the marketplace.
            </AccordionItem>

          </Accordion>
        </div>
      </div>
    </>
  )
}

export default HowList