import { transactionAction } from "../actionType";

const initialState = {
  loader: false,
  transactionListData: [],
};
export default function transaction(state = initialState, { type, payload }) {
  switch (type) {
    // case bargainAction.ADD_BARGAIN_INITIATE: {
    //   return {
    //     ...state, loader: true
    //   }
    // }
    // case bargainAction.ADD_BARGAIN_SUCCESS: {
    //   return {
    //     ...state, loader: false
    //   }
    // }
    // case bargainAction.ADD_BARGAIN_FAILURE: {
    //   return {
    //     ...state, loader: false
    //   }
    // }

    case transactionAction.TRANSACTION_LIST_INITIATE: {
      return {
        ...state,
        loader: true,
        transactionListData: [],
      };
    }
    case transactionAction.TRANSACTION_LIST_SUCCESS: {
      return {
        ...state,
        loader: false,
        transactionListData: payload,
      };
    }
    case transactionAction.TRANSACTION_LIST_FAILURE: {
      return {
        ...state,
        loader: false,
        transactionListData: [],
      };
    }
    default:
      return state;
  }
}
