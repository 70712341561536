import { combineReducers } from "redux";
import transaction from "./reducer/transactionReducer";
import gspUsdPrice from "./reducer/usdPriceReducer";
import users from "./reducer/userReducer"

const rootReducer = combineReducers({
    transaction,
    gspUsdPrice,
    users
});

export default rootReducer;
