import React, { useState, useEffect } from "react";
import { Breadcrumb, BreadcrumbItem, Button } from 'reactstrap';
import Header from '../../common-components/header/header'
import TimeCountdown from "../../pages/nfts-details/time-countdown"
import FootLogo from "../../../assets/images/footer-logo.png"
import SocialIcon1 from "../../../assets/images/social-icon1.png"
import SocialIcon2 from "../../../assets/images/social-icon2.png"
import SocialIcon3 from "../../../assets/images/social-icon3.png"
import SocialIcon4 from "../../../assets/images/social-icon4.png"
import SocialIcon5 from "../../../assets/images/social-icon5.png"
import NftImg from "../../../assets/images/nftdet-Bg.png"
import BigLogo from "../../../assets/images/big-logo.png"
import SvgIcon from "../../../assets/images/svg-icon.png"
import { BsGlobe } from "react-icons/bs"
import "./nfts-details.scss"


const NftsDetails = () => {

    const [year, setYear] = useState('')
    useEffect(() => {
        const d = new Date();
        let getYear = d.getFullYear();
        setYear(getYear)
    }, [])

    return (
        <>
            <div className='markeplaceBg'>
                <div className='container'>
                    <Header />
                    <div className='marketplace_Inn'>
                        <div className="breadcrumBx">
                            {/* <Breadcrumb> 
                                <BreadcrumbItem><a href="#">Home</a></BreadcrumbItem>
                                <BreadcrumbItem><a href="#">Explore</a></BreadcrumbItem>
                                <BreadcrumbItem active>NFT Deails</BreadcrumbItem>
                            </Breadcrumb> */}
                        </div>
                        <div className="nftDetOuter">
                            <div className="nftdetLeft">
                                <img src={NftImg} alt="" />
                                <i><img src={BigLogo} alt="" /></i>
                            </div>
                            <div className="nftdetRight">
                                <div className="nftdetHd">
                                    <h2>The Orbitians</h2>
                                    <small>Minted on Sep 30, 2022</small>
                                    <strong>Created By</strong>
                                    <span><img src={SvgIcon} alt="" />&nbsp; Game Sphere</span>
                                </div>
                                <div className="timerBg">
                                    <strong>Auction Ends In</strong>
                                    <TimeCountdown />
                                    <div className="bidbuttons">
                                        <Button>Place Bid</Button>
                                        <Button>Buy Now</Button>
                                    </div>
                                </div>
                                <div className="discCont">
                                    <strong>Description</strong>
                                    <p>The Orbitians is a collection of 10,000 unique NFTs on the Ethereum blockchain,</p>
                                    <p>There are all sorts of beings in the NFT Universe. The most advanced and friendly of the bunch are Orbitians.</p>
                                    <p>They live in a metal space machines, high up in the sky and only have one foot on Earth. These Orbitians are a peaceful 
                                        race, but they have been at war with a group of invaders for many generations. The invaders are called Upside-Downs, 
                                        because of their inverted bodies that live on the ground, yet do not know any other way to be. Upside-Downs believe that 
                                        they will be able to win this war if they could only get an eye into Orbitian territory, so they've taken to make human 
                                        beings their target.
                                    </p>
                                </div>
                                <div className="discCont">
                                    <strong>Details</strong>
                                    <span><BsGlobe />&nbsp; View on Etherscan</span>
                                    <span><BsGlobe />&nbsp; View Original</span>
                                </div>
                            </div>
                        </div>
                        <div className="homeFooter mpFoot">
                            <i><img src={FootLogo} alt="" /></i>
                            <span>GAMESPHERE TECHNOLOGY LLC</span>
                            <a href="https://www.facebook.com/gamesphere.io" target="_blank"><img src={SocialIcon1} alt="" /></a>
                            <a href="https://t.me/GameSphereIO" target="_blank"><img src={SocialIcon2} alt="" /></a>
                            <a href="https://www.instagram.com/gamesphere_io/" target="_blank"><img src={SocialIcon3} alt="" /></a>
                            <a href="https://discord.com/invite/27WgyZKN" target="_blank"><img src={SocialIcon4} alt="" /></a>
                            <a href="https://www.linkedin.com/company/gamesphere-io/" target="_blank"><img src={SocialIcon5} alt="" /></a>
                            <span>Gamesphere technology llc {year} &copy; All right reserved.</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default NftsDetails

