import React from "react";
import howit from '../../../assets/images/how-it-img.png'



const HowItWorks = () => {
  return (
    <>
        <div className="ecosystem howitOuter">
            <div className="container">
                <h2 className="ecoHd">How it works?</h2>
                <div className="howitBx">
                    <img src={howit} alt="" />
                </div>
            </div>
        </div>
    </>
  )
}

export default HowItWorks